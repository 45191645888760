import { IFlagListItem } from '@mod/filters/flags-filter.model';
import { Flags } from '@mod/symbol-smiley/symbol-smiley.model';

export const FLAGS_LIST: IFlagListItem[] = [
  {
    name: 'No',
    value: Flags.No,
    img: 'assets/img/RedSmile.svg',
  },
  {
    name: 'Maybe',
    value: Flags.Maybe,
    img: 'assets/img/YellowSmile.svg',
  },
  {
    name: 'Yes',
    value: Flags.Yes,
    img: 'assets/img/GreenSmile.svg',
  },
  {
    name: 'Never',
    value: Flags.Never,
    img: 'assets/img/NeverSmile.svg',
  },
  {
    name: 'None',
    value: Flags.None,
    img: 'assets/img/GreySmile.svg',
  },
];
