import { ExchangeCountriesCodes, Positions, Signals } from '@const';

export interface IStockSubscription {
  subscriptionId: string;
  symbol: {
    symbol: string;
    country_code: ExchangeCountriesCodes;
  };
}

export interface DetailsForDividendsStrategyChartModel {
  positionEntryPrice: number | null;
  positionExDatePrevClosePrice: number | null;
  breakEven: number | null;
  costBasis: number | null;
}

export interface ISaveChartShapes {
  symbolId: number;
  state: { shapes: Array<Record<string, unknown>>; isError: boolean };
}

export enum DividendsPaymentPeriods {
  Special = 'Special',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  SemiAnnual = 'SemiAnnual',
  Annual = 'Annual',
  Other = 'Other',
}

export interface DividendsStrategyDetailsResponseModel {
  security_id: number;
  symbol: string;
  company_name: string;
  exchange_id: number;
  close: number | null;
  date: string; // "2024-07-22T00:00:00.000Z"
  payment_period: DividendsPaymentPeriods | null;
  entry_price: number | null;
  exit_price: number | null;
  is_dow_jones: number | null;
  is_nasdaq: number | null;
  is_sp_100: number | null;
  is_sp_500: number | null;
  position: Positions | null; // "L"
  signal: Signals | null; // null
  has_enough_data: 1 | 0;
  // details different for each year interval
  break_evens_avg_days_count: number | null;
  break_evens_max_days_count: number | null;
  break_evens_min_days_count: number | null;
  closed_dividends_count: number | null;
  interval_years: number;
  closed_break_evens_count: number | null;
  closed_break_evens_ratio: number | null;
  dividend_yield: number | null;
  earnings_share: number | null;
  dividend_share: number | null;
  roi: number | null;
  signal_shares_count: number | null;
  shares_count: number | null;
  signal_entry_price: number | null;
  total_dividends_count: number | null;
  total_profit: number | null;
  wins: number | null;
  min_trade_days: number | null;
  avg_trade_days: number | null;
  longest_trade_days: number | null;
  volume: number | null;
  market_cap: number | null;
  is_new: boolean;
  is_open: boolean;
  is_exit: boolean;
  next_dividend_id: number | null;
  next_ex_date: string | null;
  ex_date: string | null;
  ex_date_minus_1: string | null;
  ex_date_minus_1_close: number | null;
}

export type DividendsStrategyScannerResponseModel = DividendsStrategyDetailsResponseModel;

export interface DividendsStrategyDividendTradesResponseModel {
  strategy_id: number;
  interval_months: number;
  duration_days: number;
}

export interface DividendsStrategyDividendsResponseModel {
  security_id: number;
  value: number;
  date: string;
  payment_date: string;
  payment_period: DividendsPaymentPeriods | null;
  trades: Array<DividendsStrategyDividendTradesResponseModel>;
}
