import { Flags } from "@mod/symbol-smiley/symbol-smiley.model";

export enum WtfSymbolsOptions {
  SP100 = 'sp100',
  SP500 = 'sp500',
  Nasdaq = 'nasdaq',
  DowJones = 'dowJones',
  Other = 'other',
}

export enum Wtf200MAOptionsWtf200MAOptions {
  above200MADay = 'above',
  below200MADay = 'below',
  ignore200MADay = 'ignore'
}

export interface IVolumeValue {
  filterValue: number | string;
  visibleValue: string;
}

export interface IWtfScannerFilter {
  flags: Flags[];
  symbolOptions: WtfSymbolsOptions[];
  stockPriceFrom: string;
  stockPriceTo: string;
  ma200: Wtf200MAOptionsWtf200MAOptions;
  profitFactorFrom: string;
  profitFactorTo: string;
  winPercentFrom: string;
  winPercentTo: string;
  volumeFrom: IVolumeValue;
  volumeTo: IVolumeValue;
}

export enum TextInputKeys {
  StockPriceFrom = 'stockPriceFrom',
  StockPriceTo = 'stockPriceTo',
  ProfitFactorFrom = 'profitFactorFrom',
  ProfitFactorTo = 'profitFactorTo',
  WinPercentFrom = 'winPercentFrom',
  WinPercentTo = 'winPercentTo',
  VolumeFrom = 'volumeFrom',
  VolumeTo = 'volumeTo',
}

export interface IDisplayedFilterValues {
  symbolOptions: string;
  symbolOptionsShort: string;
  stockPrice: string;
  ma200: string;
  profitFactor: string;
  winPercent: string;
  volume: string;
}
