import { Component, EventEmitter, Injector, OnInit, Output, effect, input } from '@angular/core';
import { isNumberHasValue } from '@const';
import { VideoHubPlayer } from '@t/video-hub/video-hub-player.interface';
import Player from '@vimeo/player';

@Component({
  selector: 'app-vimeo-player',
  standalone: true,
  imports: [],
  templateUrl: './vimeo-player.component.html',
  styleUrl: './vimeo-player.component.scss',
})
export class VimeoPlayerComponent implements OnInit, VideoHubPlayer {
  public videoUrl = input.required<string>();
  public videoStartTime = input<number>();
  @Output()
  public videoEnd = new EventEmitter<void>();
  @Output()
  public videoLoaded = new EventEmitter<void>();
  private player: Player | undefined;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    effect(
      () => {
        if (this.videoUrl()) {
          this.player?.destroy();
          this.configurePlayer();
        }
      },
      { injector: this.injector },
    );

    effect(
      () => {
        const time = this.videoStartTime();
        if (isNumberHasValue(time)) {
          this.player?.setCurrentTime(time);
        }
      },
      { injector: this.injector },
    );
  }

  public async getVideoUrl(): Promise<string> {
    const timestamp = await this.player?.getCurrentTime();
    return this.videoUrl() + `#t=${timestamp ?? 0}s`;
  }

  public pause(): void {
    this.player?.pause();
  }

  public async getCurrentTime(): Promise<number> {
    return Math.trunc((await this.player?.getCurrentTime()) ?? 0);
  }

  public async setCurrentTime(currentTime: number): Promise<number> {
    return this.player?.setCurrentTime(currentTime);
  }

  public async play(): Promise<void> {
    return this.player?.play();
  }

  private onVideoEnd(): void {
    this.videoEnd.emit();
  }

  private onVideoLoaded(): void {
    this.videoLoaded.emit();
  }

  private configurePlayer(): void {
    this.player = new Player(`vimeo-player-${this.videoStartTime()}`, {
      url: this.videoUrl(),
      start_time: this.videoStartTime() ?? 0,
      responsive: true,
      pip: false,
      byline: false,
      cc: false,
      portrait: false,
      title: false,
      vimeo_logo: false,
      interactive_markers: false,
      autoplay: true,
      volume: false,
      transcript: false,
      quality_selector: false,
      speed: false,
    });

    this.player.on('ended', this.onVideoEnd.bind(this));
    this.player.on('loaded', this.onVideoLoaded.bind(this));
  }
}
