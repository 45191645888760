import {
  DividendsPaymentPeriodFilterModel,
  DividendsStrategyScannerFilterModel,
  ExDatePeriodFilterModel,
  ExDatePeriodOptions,
} from '@c/dividends-strategy-content/dividends-strategy-scanner-filter/dividends-strategy-scanner-filter.model';
import { DividendsPaymentPeriods } from '@mod/dividends-strategy/dividends-strategy.model';

export const EMIT_DIVIDENDS_FILTER_CHANGE_DEBOUNCE_TIME_MS = 800;
export const DEFAULT_IS_DIVIDENDS_STRATEGY_FILTERS_HIDDEN = false;

export const DEFAULT_DIVIDENDS_STRATEGY_FILTERS_STATE: DividendsStrategyScannerFilterModel = {
  divPerShareFrom: '',
  divPerShareTo: '',
  divYieldPercentFrom: '',
  divYieldPercentTo: '',
  stockPriceFrom: '',
  stockPriceTo: '',
  breakEvenPeriodYears: 3,
  avgBreakEvenDaysFrom: '',
  avgBreakEvenDaysTo: '',
  roiFrom: '',
  roiTo: '',
  volumeFrom: {
    filterValue: '',
    visibleValue: ''
  },
  volumeTo: {
    filterValue: '',
    visibleValue: ''
  },
  marketCapFrom: {
    filterValue: '',
    visibleValue: ''
  },
  marketCapTo: {
    filterValue: '',
    visibleValue: ''
  },
  dividendPaymentPeriodOptions: [],
  exDatePeriod: { value: ExDatePeriodOptions.NextDay, details: { from: '', to: '' } } as const,
} as const;

export const RECOMMENDED_DIVIDENDS_STRATEGY_FILTERS_STATE: DividendsStrategyScannerFilterModel = {
  divPerShareFrom: '',
  divPerShareTo: '',
  divYieldPercentFrom: '',
  divYieldPercentTo: '',
  stockPriceFrom: '',
  stockPriceTo: '',
  breakEvenPeriodYears: 3,
  avgBreakEvenDaysFrom: '',
  avgBreakEvenDaysTo: '',
  roiFrom: '',
  roiTo: '',
  volumeFrom: {
    filterValue: '',
    visibleValue: ''
  },
  volumeTo: {
    filterValue: '',
    visibleValue: ''
  },
  marketCapFrom: {
    filterValue: '',
    visibleValue: ''
  },
  marketCapTo: {
    filterValue: '',
    visibleValue: ''
  },
  dividendPaymentPeriodOptions: [],
  exDatePeriod: { value: ExDatePeriodOptions.NextDay, details: { from: '', to: '' } } as const,
} as const;

export const dividendsPaymentPeriodOptionsForFilter: Array<DividendsPaymentPeriodFilterModel> = [
  { id: 1, title: 'Weekly', value: DividendsPaymentPeriods.Weekly },
  { id: 2, title: 'Monthly', value: DividendsPaymentPeriods.Monthly },
  { id: 3, title: 'Quarterly', value: DividendsPaymentPeriods.Quarterly },
  { id: 4, title: 'Semiannually', value: DividendsPaymentPeriods.SemiAnnual },
  { id: 5, title: 'Annually', value: DividendsPaymentPeriods.Annual },
  { id: 6, title: 'Other', value: DividendsPaymentPeriods.Other },
] as const;

export const exDatePeriodOptionsForFilter: Array<ExDatePeriodFilterModel> = [
  { id: 1, title: 'Next Day', value: ExDatePeriodOptions.NextDay },
  { id: 2, title: 'Next 5 Days', value: ExDatePeriodOptions.Next5Days },
  { id: 3, title: 'Next 10 Days', value: ExDatePeriodOptions.Next10Days },
  { id: 4, title: 'Next 30 Days', value: ExDatePeriodOptions.Next30Days },
  { id: 5, title: 'This Week', value: ExDatePeriodOptions.ThisWeek },
  { id: 6, title: 'Next Week', value: ExDatePeriodOptions.NextWeek },
  { id: 7, title: 'This Month', value: ExDatePeriodOptions.ThisMonth },
] as const;
